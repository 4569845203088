import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'

export const Hero = ({ heading, subheading, leadText, children }) => (
    <Box className="hero" as="header">
        <Flex className="heroLead" textAlign="center">
            <Box className="heroText" mx="auto" maxWidth="container.sm" pt={8} px={8} pb={10}>
                {heading && (
                    <Heading size="2xl" as="h1" lineHeight={1.2}>
                        {heading}
                    </Heading>
                )}
                {subheading && (
                    <Heading as="h2" size="md" fontStyle="italic">
                        {subheading}
                    </Heading>
                )}
                {leadText && (
                    <Text mt={24} fontSize="md">
                        {leadText}
                    </Text>
                )}
            </Box>
        </Flex>
        {children && <Box className="heroMain">{children}</Box>}
    </Box>
)
