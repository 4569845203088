import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { PageLayout } from '../components/PageLayout'
import { Hero } from '../components/Hero'
import { Link } from '../components/Link'

export const Head = () => (
    <>
        <title>Page Not Found | MassBay Community College Website Refresh Orientier Proposal Hub</title>
        <meta name="robots" content="noindex" />
    </>
)

const hero = <Hero heading="Hmm...we couldn't find that in the stacks." />

const NotFoundPage = () => (
    <PageLayout hero={hero}>
        <Box fontSize="md" maxWidth="container.md" mx="auto" p={8}>
            <Text>
                Sorry for the inconvenience. Feel free to <Link destination="mailto:dev@orientier.com">contact us</Link>
                , or use the menu in the upper left to browse the rest of the site.
            </Text>
        </Box>
    </PageLayout>
)

export default NotFoundPage
